<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog || add_sub_dialog || edit_sub_dialog)}">
    <MDBCardBody>
      <MDBContainer fluid class="mt-3">
        <MDBRow>
          <MDBCol class="mb-2 text-end">
            <!-- <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn> -->
            <MDBBtn color="info" @click="add_dialog = true">{{ $t("MEMBER-TAG.ADD-ITEM") }}</MDBBtn>  
          </MDBCol>
        </MDBRow>
        <DataTable
          :header="headers"
          :tabledata="desserts"
          v-model:total="total"
          v-model:options="options"
          v-model:loading="loading"
        >
          <template v-slot:name="{ item }">
            <div v-for="(name, k) in item.name" :key="k">
              {{ $t(`LANG.${k}`) + " : " + name }}  
            </div>
          </template>
          <!-- 時間轉換 -->
          <template v-slot:created_at="{ item }">
            {{ Common.TimeFormat(item.created_at) }}
          </template>
          <!-- 操作template -->
          <!-- <template v-slot:uuid="{ item }">
            <a @click="EditUUid = item.uuid" data-bs-toggle="tooltip" :title="$t('PRODUCT-CATEGORY.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
            </a>
            <a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('PRODUCT-CATEGORY.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
            </a>
          </template> -->
          <template v-slot:edit="{ item }">
            <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
              <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
          <template v-slot:uuid="{ item }">
            <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
              <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
            </MDBBtn>
          </template>
        </DataTable>
      </MDBContainer>
    </MDBCardBody>
  </MDBCard>
  <ProductTagForm action="insert" v-model="add_dialog"></ProductTagForm>
  <ProductTagForm action="update" v-model="edit_dialog" v-model:uuid="EditUUid"></ProductTagForm>
</template>

<style scoped>
  .sub-category {
    list-style: auto;
    text-align: start;
  }
  .sub-category li {
    border-bottom: 1px solid;
    width: fit-content;
  }
  .sub-div {
    display: inline-block;
    margin-right: 10px;
  }
</style>

<script>
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import ProductTagForm from '@/components/Form/Product/ProductTagForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "ProductTag",
  components: {
    DataTable,
    MDBContainer,
    MDBIcon,
    Breadcrumbs,
    ProductTagForm,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody
  },
  setup() {
    const i18n = useI18n();
    const add_dialog = ref(false);
    const edit_dialog = ref(false);
    const add_sub_dialog = ref(false);
    const edit_sub_dialog = ref(false);
    const loading = ref(true);
    const EditUUid = ref("");
    const AddSubUUid = ref("");
    const EditSubUUid = ref("");
    const total = ref(0);
    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["sort"],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });
    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",
        width: "50px",class: "text-center"
      },
      { text: i18n.t("COMMON.NAME"), value: "name" },
      { text: i18n.t("COMMON.SORT"), value: "sort" ,class: "text-center"},
      { text: i18n.t("COMMON.CREATE_AT"), value: "created_at",width: 175 },
      { text: i18n.t("COMMON.EDIT"), value: "edit", sortable: false,width: "50px" ,class: "text-center"},
      { text: i18n.t("COMMON.DELETE"), value: "uuid", sortable: false,width: "50px" ,class: "text-center"}
    ]);
    const desserts = ref([]);
    const Common = CommonService;

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/ProductTag",
          {
            params: CommonService.SortHandleObject(options)
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const DeleteAction = function(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/ProductTag/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

    const AddSub = function(uuid) {
      AddSubUUid.value = uuid;
      add_sub_dialog.value = true;
    };

    return {
      add_dialog,
      edit_dialog,
      add_sub_dialog,
      edit_sub_dialog,
      loading,
      EditUUid,
      AddSubUUid,
      EditSubUUid,
      options,
      headers,
      total,
      desserts,
      Common,
      GetTable,
      AddSub,
      DeleteAction
    };
  },
  mounted() {
    this.GetTable();
  },
  watch: {
    options: {
      handler() {
        this.GetTable();
      },
      deep: true
    }
  },
  provide () {
    return {
      GetTable: this.GetTable
    }
  }
};
</script>